import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Link, Card, CardContent} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    //position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      //height: '140vh',
      //minHeight: 500,
     //maxHeight: 1300,
    },
  },
  container: {
    marginTop: "50px",
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title : {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",
    color:  theme.palette.secondary.light,
  },
  text: {
    color: theme.palette.primary.dark,
  }
});

function Stream(props) {
  const {classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
          <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={10}>
            <Grid item>
                <a class="anchor" id="stream"/>
                <Typography variant={"h3"} className={classes.title}>
                    Stream
                </Typography>
            </Grid>
            <Grid item style={{textAlign:"center", maxWidth: "100%"}}>
              <iframe 
              allowfullscreen="" 
              allowtransparency="true" 
              scrolling="no" 
              src="https://CrossViewLutheran.sermon.net/player/live/21098824?repeat=false&amp;autostart=false&amp;counter_color=rgba(0%2C0%2C0%2C1)&amp;counter_background=rgba(255%2C255%2C255%2C1)" 
              style={{minWidth: "100%"}} width="600px" height="400px" frameborder="0">
              </iframe>
              <Card style={{marginTop:"10px", marginBottom: "10px"}}>
                <CardContent>
                  <Typography >
                    The service should start at 3pm or shortly after. The stream should hopefully start a few minutes before 3pm.
                  </Typography>
                  <Typography style={{ marginTop: "15px"}}>
                    If the above player is not working please try <Link href="https://www.crossview.net/worship/media.cfm" target="_blank" color="secondary">viewing directly on our church's website.</Link>
                  </Typography>
                  <Typography style={{ marginTop: "15px"}}>
                    We'd also love if you signed our <Link href="https://forms.gle/9wkawcUgJMgDRVeJ6" target="_blank" color="secondary">digital guest book.</Link>
                  </Typography>
                  <Typography style={{ marginTop: "15px"}}>
                    Please also join us at 6:30pm-7:30pm to chat!<Link href="#meet_n_greet" target="_blank" color="secondary">See here for more info.</Link>
                  </Typography>
              </CardContent>
            </Card>
            </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Stream.propTypes = {

};

export default withStyles(styles)(Stream);
