import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Link, Menu, MenuItem, IconButton} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AppBar from '../components/AppBar/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar/Toolbar';
import config from '../config'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme) => ({
    title: {
        fontSize: 40,
        fontFamily: "'Kaushan Script', cursive",
        textTransform: "none",
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40,
        },
    },
    placeholder: toolbarStyles(theme).root,
    toolbar: {
        justifyContent: 'space-between',
    },
    left: {
        flex: 1,
    },
    leftLinkActive: {
        color: theme.palette.primary.main,
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    rightLink: {
        fontSize: 16,
        color: theme.palette.primary.dark,
        marginLeft: theme.spacing(3),
    },
    linkSecondary: {
        color: theme.palette.secondary.main,
    },
});

function HeaderBar(props) {
    const { classes } = props;
    const matches = useMediaQuery('(min-width:600px)');
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
        <AppBar position="fixed">
            <Toolbar className={classes.toolbar}>
            { matches &&
                <div className={classes.left} />
            }
            
            <Link
                variant="h6"
                underline="none"
                color="inherit"
                className={classes.title}
                href="/premium-themes/onepirate/"
            >
                {config.header.title}
            </Link>
            {
                matches && 
                <div className={classes.right}>
                    {
                        config.header.links.map(el=>{
                            return (
                                <Link
                                color="inherit"
                                variant="h6"
                                underline="none"
                                key={el.link}
                                className={el.emphasize ? clsx(classes.rightLink, classes.linkSecondary): classes.rightLink }
                                href={el.link}
                                >
                                    {el.title}
                                </Link>
                            )
                        })
                    }
                </div>
            }
            {
                !matches &&
                <React.Fragment>
                    <IconButton onClick={handleOpenMenu}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {
                            config.header.links.map(el=>{
                                return (
                                    <MenuItem>
                                        <Link
                                        color="inherit"
                                        underline="none"
                                        key={el.link}
                                        href={el.link}   
                                        >
                                            {el.title}
                                        </Link>
                                    </MenuItem>
                                    
                                )
                            })
                        }
                    </Menu>
                </React.Fragment>
            }
            </Toolbar>
        </AppBar>
        <div className={classes.placeholder} />
        </div>
    );
}
  
HeaderBar.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderBar);