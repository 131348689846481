  import withRoot from './withRoot';
import React from 'react';
import logo from './logo.svg';
import HeaderBar from './views/HeaderBar';
import Hero from './views/Hero'
import Overview from './views/Overview'
import Registry from './views/Registry'
import Rsvp from './views/Rsvp'
import Stream from './views/Stream'
import MeetAndGreet from './views/Meet_and_Greet'
import Footer from './views/Footer';



function App() {
  return (
    <React.Fragment>
      <HeaderBar/>
      <Hero/>
      <Overview/>
      <Rsvp/>
      <Registry/>
      <Footer/>

    </React.Fragment>
  );
}

export default withRoot(App);
