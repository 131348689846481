import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Link, Card, CardContent} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      //height: '140vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: "50px",
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title : {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",
    color: theme.palette.secondary.dark,
  },
  text: {
    color: theme.palette.secondary.dark,
  }
});

function Registry(props) {
  const {classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
          <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={10}>
            <Grid item>
                <a class="anchor" id="registry"/>
                <Typography variant={"h3"} className={classes.title}>
                    Registry
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={"h5"}>
                    We are registered at the following locations:
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={5} justify={"center"} alignItems={"center"}>
                    <Grid item>
                      <Card style={{padding: "20px"}} align={"center"}>
                        <CardContent>
                          <Link href="http://tgt.gifts/bc4d016061d14095b5c3024fc9f47162" target="_blank">
                            <img src={process.env.PUBLIC_URL + "/assets/target.png"} style={{height: "100px"}}/>
                            <Typography align={"center"} className={classes.text}>
                              To Target
                            </Typography>
                          </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item>
                    <Card style={{padding: "20px"}} align={"center"}>
                        <CardContent>
                          <Link href="https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/549223097" target="_blank">
                            <img src={process.env.PUBLIC_URL + "/assets/bbnb.jpg"} style={{height: "100px"}}/>
                            <Typography align={"center"} className={classes.text}>
                              To Bed Bath & Beyond
                            </Typography>
                          </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item>
                    <Card style={{padding: "20px"}} align={"center"}>
                        <CardContent>
                          <Link href="https://www.amazon.com/wedding/jordan-goetze-kandace-fischer--january-2021/registry/11ZC5ALD269R4" target="_blank">
                            <img src={process.env.PUBLIC_URL + "/assets/amazon.png"} style={{height: "100px"}}/>
                            <Typography align={"center"} className={classes.text}>
                              To Amazon
                            </Typography>
                          </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item>
                    <Card style={{padding: "20px"}} align={"center"}>
                        <CardContent>
                          <Link href="https://www.wayfair.com/registry/wedding/afc35559-9210-4788-83db-a2e85c4c9d80" target="_blank">
                            <img src={process.env.PUBLIC_URL + "/assets/wayfair.jpg"} style={{height: "100px"}}/>
                            <Typography align={"center"} className={classes.text}>
                              To Wayfair
                            </Typography>
                          </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
              <Card>
                <CardContent>
                    <Typography variant={"h5"}>
                        We are also registered with Celebrity China
                    </Typography>
                    <br/>
                    <Typography variant={"paragraph"}>
                        We currently have two coffee service sets in the <Link href="http://www.celebritychina.com/china.html" taget="_blank" color="secondary">Florance Platinum style</Link>, and would love to round out our set with additional pieces. 
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant={"h5"}>
                        Sample images:
                    </Typography>
                    <ul>
                        <li>
                            <Link href="http://www.celebritychina.com/china.html" taget="_blank" color="secondary">See the Florance Platinum section</Link>
                        </li>
                        <li>
                            <Link href="http://www.celebritychina.com/tableware.html" taget="_blank" color="secondary">See the Eternity section</Link>
                        </li>
                        <li>
                            <Link href="http://www.celebritychina.com/tableware.html" taget="_blank" color="secondary">See the Montrose Fully Forged Cutlery section</Link>
                        </li>
                    </ul>
                    <Typography variant={"h5"}>
                        How to access the registry:
                    </Typography>
                    <Typography variant={"paragraph"}>
                        To Order, contact Julia Hernandez via phone ( 913-308-0080 ) or email (jhernandez@celebritychina.com).
                    </Typography>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Registry.propTypes = {

};

export default withStyles(styles)(Registry);
