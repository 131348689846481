import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Link} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.dark,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      //height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: "50px",
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title : {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",
    color: theme.palette.primary.dark,
  },
  text: {
    color: theme.palette.primary.dark,
  }
});

function Overview(props) {
  const {classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
          <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={10}>
            <Grid item>
                <a class="anchor" id="location-and-details"/>
                <Typography variant={"h3"} className={classes.title}>
                    Location and Details
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={5} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"} align={"center"} style={{fontWeight: "bold"}} className={classes.text}>
                            Where: 
                        </Typography>
                        <Typography variant={"subtitle1"} align={"center"} className={classes.text}>
                           The Van Dusen Mansion in Minneapolis, MN (<Link href="https://goo.gl/maps/vXLUN8d8ydxYmgy88" target="_blank">Directions</Link>)
                            <br/>
                           <b>Note:</b> Onsite parking is available in the <br/>Minnesota Conference United <br/> Church of Christ (behind the mansion). 
                          
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"h5"} align={"center"} style={{fontWeight: "bold"}} className={classes.text}>
                            When: 
                        </Typography>
                        <Typography variant={"subtitle1"} align={"center"} className={classes.text}>
                            January 16th, 2021, 4:00pm
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{display: "none"}}>
                <Typography variant={"h5"} align={"center"} style={{fontWeight: "bold"}} className={classes.text}>
                    Covid-19:
                </Typography>
                <Typography  className={classes.text}>
                    At this time we are still planning on to have the wedding Jan 16th. We have delayed our reception exactly a one year, assuming that at that time local restrictions 
                    are lifted enough to allow us to hold a reception. Be on the lookout for invites to our belated Reception/One-Year Anniversary Party early next winter.
                </Typography>
                <br/>
                <Typography className={classes.text}>
                   Thanks to some wonderful people at our church we will be able to live stream the service, see the <Link href="#stream">streaming section </Link>
                    for the player a little before 3pm the day of if you wish to stream. We would also love to have you <Link href="#rsvp">RSVP too!</Link>
                </Typography>
            </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Overview.propTypes = {

};

export default withStyles(styles)(Overview);
