import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Button} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      //height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {

    
    marginTop: "50px",
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title : {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",
    color: theme.palette.primary.dark,
  },
  text: {
    color: theme.palette.primary.dark,
  }
});

function Rsvp(props) {
  const {classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
          <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={10}>
            <Grid item>
                <a class="anchor" id="rsvp"/>
                <Typography variant={"h3"} className={classes.title}>
                    RSVP
                </Typography>
            </Grid>
            <Grid item style={{textAlign:"center"}}>
              
              <Button
               variant="contained" 
               href="https://forms.gle/fAb1xWnWgywr4Z8KA" 
               target="_blank"
               color="secondary"
               style={{marginBottom: 25}}
               >
                 Open RSVP Form
              </Button>
              <Typography style={{textAlign:"left", display: "none"}}>
                The state of Minnesota is currently under the effect of an emergency directive by the governor to limit the number of people at events, as such
                the number of guests we are able to invite is drastically reduced from the 170 we were originally planning on. As a result many of you will receive
                a letter with your wedding invite. Some of the letters will contain an in-person RSVP code to let us know you plan on attending in person. For
                those of you who do not receive an RSVP code we would still love to have you live-stream the service from this website. There is also an option
                to RSVP to stream on the form so let us know you are watching.
              </Typography>
            </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Rsvp.propTypes = {

};

export default withStyles(styles)(Rsvp);
