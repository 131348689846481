import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from '../components/Typography/Typography';
import HeroLayout from './HeroLayout';
import config from '../config'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const backgroundImage = process.env.PUBLIC_URL + '/assets/wedding.jpg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: theme.palette.primary.light, // Average color of the background image.

    [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'top',
        backgroundSize: "100%",   
    },
    [theme.breakpoints.up('sm')]: {
        backgroundPosition: 'center',
        backgroundSize: "auto 100%", 
    },

  },
  button: {
    minWidth: 200,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  headding: {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",

    [theme.breakpoints.down('sm')]: {
        fontSize: 40,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 60,
    },


  }
});

function Hero(props) {
  const { classes } = props;
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <HeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center" className={classes.headding}>
        Join us January 16, 2022
      </Typography>
      
        { config.rsvp.enabled && 
            <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="/premium-themes/onepirate/sign-up/"
            >
                Register
            </Button>
        }
        {
            !matches && <React.Fragment><br/><br/><br/></React.Fragment>
        }
        <Typography variant="body2" color="inherit" className={classes.more} style={{display: "none"}}>
            COVID-19 has unfortunately impacted our wedding plans. Please see below for more information.
        </Typography>
    </HeroLayout>
  );
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hero);
