import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import { AppBar as MuiAppBar } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        color: theme.palette.secondary.dark,
        background: theme.palette.primary.dark,
    }
});

function AppBar(props){
    return <MuiAppBar  elevation={0} position="static"  {...props} />;
}

AppBar.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppBar);
