import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Grid, Button} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.dark,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      //height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {

    
    marginTop: "50px",
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title : {
    fontFamily: "'Kaushan Script', cursive",
    textTransform: "none",
    color: theme.palette.primary.dark,
  },
  text: {
    color: theme.palette.primary.dark,
  }
});

function MeetAndGreet(props) {
  const {classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
          <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={10}>
            <Grid item>
                <a class="anchor" id="meet_n_greet"/>
                <Typography variant={"h3"} className={classes.title}>
                    Meet and Greet
                </Typography>
            </Grid>
            <Grid item style={{textAlign:"center"}}>
              
              <Button
               variant="contained" 
               href="https://target.zoom.us/j/6454696388" 
               target="_blank"
               color="secondary"
               style={{marginBottom: 25}}
               >
                 Join Meet and Greet!
              </Button>
              <Typography style={{textAlign:"left"}}>
                Please join us at 6:30pm-7:30 January 16th to chat with us on zoom. We figured this would be a good compromise to visit with our guests without needing to put anyone at risk, or violate
                any of the governor's COVID-19 restrictions. We will hang out on the call for the whole hour, and may stay longer if we have people to keep chatting with, so please feel free to pop in and say hi at any point.
              </Typography>
            </Grid>
        </Grid>
      </Container>
    </section>
  );
}

MeetAndGreet.propTypes = {

};

export default withStyles(styles)(MeetAndGreet);
