
const config = {
  header: {
      title: "Jordan & Kandace",
      links: [
          {
              title: "Location and Details",
              link: "#location-and-details",
              emphasize: false,
          },
          {
            title: "RSVP",
            link: "#rsvp",
            emphasize: true,
          },
      ]
  },

  rsvp : {
      enabled: false,
      code: "1234",
  }
}

export default config
